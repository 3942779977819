
import { defineComponent, PropType } from "vue";
import variables from "@/router/api";

export default defineComponent({
  name: "policy-details-table",
  components: {},
  props: {
    widgetClasses: String,
    policyDetails: Array as PropType<Record<string, any>[]>,
    publicId: String,
    grossPremium: String,
    status: {
      type: String,
      required: false,
      default: "Active",
    },
  },
  methods: {
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    // addDatatableScripts() {
    //   // For buttons dispgitlay
    //   const jQueryScript = document.createElement("script");
    //   const datatableScript = document.createElement("script");
    //   const datatableScript2 = document.createElement("script");
    //   const datatableScript3 = document.createElement("script");
    //   const datatableScript4 = document.createElement("script");
    //   const datatableScript5 = document.createElement("script");
    //   const datatableScript6 = document.createElement("script");

    //   // For jQuery
    //   jQueryScript.setAttribute(
    //     "src",
    //     "http://ajax.googleapis.com/ajax/libs/jquery/1.9.1/jquery.min.js"
    //   );
    //   document.head.appendChild(jQueryScript);

    //   datatableScript.setAttribute(
    //     "src",
    //     "https://cdn.datatables.net/buttons/1.6.5/js/dataTables.buttons.min.js"
    //   );
    //   document.head.appendChild(datatableScript);

    //   // For HTML5 buttons display
    //   datatableScript5.setAttribute(
    //     "src",
    //     "https://cdn.datatables.net/buttons/1.6.5/js/buttons.html5.min.js"
    //   );
    //   document.head.appendChild(datatableScript5);

    //   // For Jszip
    //   datatableScript2.setAttribute(
    //     "src",
    //     "https://cdnjs.cloudflare.com/ajax/libs/jszip/3.1.3/jszip.min.js"
    //   );
    //   document.head.appendChild(datatableScript2);

    //   // For PDF exporting
    //   datatableScript3.setAttribute(
    //     "src",
    //     "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.53/pdfmake.min.js"
    //   );
    //   document.head.appendChild(datatableScript3);

    //   // For PDF fonts
    //   datatableScript4.setAttribute(
    //     "src",
    //     "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.53/vfs_fonts.js"
    //   );
    //   document.head.appendChild(datatableScript4);

    //   // For print button
    //   datatableScript6.setAttribute(
    //     "src",
    //     "https://cdn.datatables.net/buttons/1.6.5/js/buttons.print.min.js"
    //   );
    //   document.head.appendChild(datatableScript6);
    // },
  },
});
